require('./bootstrap');

// sweet alert2
window.Swal = require('sweetalert2');


// carousel
// import 'owl.carousel';

window.$ = window.jQuery = require('jquery');

var Chart = require('chart.js')
